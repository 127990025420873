import React from "react";

const Projects = () => {
  return (
    <div id="projects">
      <div>
        <div class="max-w-screen-xl mx-auto">
          <div class="flex flex-wrap md:h-screen">
            <div class="flex justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src="https://i.postimg.cc/tC8Q21Wg/eliashelander-todo-react-mock.png"
                alt="Todo react app"
                class="object-contain h-3/4 text-center mb-4"
              />
            </div>
            <div class="flex flex-col justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <div class="px-24 pb-12 md:pb-0">
                <h2 class="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">
                  Todo React
                </h2>
                <p class="mt-4 text-xl text-gray-500">
                  Todo app where you can add, mark as done and delete items.
                  Project is created with: Node.js and React.
                </p>
                <div class="mt-5 flex">
                  <button
                    onClick={() =>
                      window.open(
                        "https://todo-react.eliashelander.com/",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    Demo
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://github.com/eliashelander/todo-react",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    GitHub
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50">
        <div class="max-w-screen-xl mx-auto">
          <div class="flex flex-wrap-reverse md:flex-wrap md:h-screen">
            <div class="flex flex-col justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <div class="px-24 pb-12 md:pb-0">
                <h2 class="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">
                  Rentify
                </h2>
                <p class="mt-4 text-xl text-gray-500">
                  A platform where you can rent anything from your peers. Built
                  as a full stack application. Project is built with: Node.js,
                  Express.js, ElephantSQL, PostgreSQL, React and Firebase.
                </p>
                <div class="mt-5 flex">
                  <button
                    onClick={() =>
                      window.open(
                        "https://rentify.eliashelander.com/",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    Demo
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://github.com/eliashelander/rentify",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    GitHub
                  </button>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src="https://i.postimg.cc/wMyNmhMh/Screenshot-2021-09-15-at-16-22-05-macbookpro13-front.png"
                alt="Todo react app"
                class="object-contain h-full text-center mb-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-screen-xl mx-auto">
          <div class="flex flex-wrap md:h-screen">
            <div class="flex justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src="https://i.postimg.cc/Gm0FvSk9/Screenshot-2021-09-15-at-16-34-08-ipad-spacegrey-portrait.png"
                alt="Todo react app"
                class="object-contain h-3/4 text-center mb-4"
              />
            </div>
            <div class="flex flex-col justify-center items-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <div class="px-24 pb-12 md:pb-0">
                <h2 class="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">
                  Food tracker
                </h2>
                <p class="mt-4 text-xl text-gray-500">
                  Track your ingredients at home and know what and when you need
                  to go shopping. Project is created with: Node.js, Express.js
                  and React.
                </p>
                <div class="mt-5 flex">
                  <button
                    onClick={() =>
                      window.open(
                        "https://food-tracker.eliashelander.com/",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    Demo
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://github.com/eliashelander/food-tracker",
                        "_blank"
                      )
                    }
                    class="mr-4 px-3 py-1 border-2 border-gray-700 text-gray-700 rounded-lg hover:border-gray-900 hover:text-gray-900"
                  >
                    GitHub
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
