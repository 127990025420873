import React from "react";

const About = () => {
  return (
    <div class="flex flex-wrap h-screen" id="about">
      <div class="p-12 flex items-center justify-center w-full w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 bg-gray-50">
        <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          I fell for coding while developing products based on customer feedback
          as a 3x start up founder.
          {` Graduated from </salt> as a full stack developer. 
          After 500+ development hours during 3 months.`}
          <br /><br />I will work with you as an innovative company using modern
          JavaScript tech stacks to solve interesting challenges for the world.
          You will get a dedicated developer who performs best with
          responsibilities and high speed. With the right company I don’t clock
          in, I live it.
        </p>
      </div>
      <img
        src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
        alt="Computer at desk"
        class="hidden md:block object-cover w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 bg-gray-50"
      />
    </div>
  );
};

export default About;
