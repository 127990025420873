import React from "react";

const Hero = () => {
  return (
    <div className="bg-white ninetyvh">
      <div className="flex flex-col items-center justify-center text-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen">
        <p className="mt-2 text-5xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          I'm Elias
        </p>
        <p className="mt-4 text-2xl text-gray-500">
          A Full stack Developer
        </p>
      </div>
    </div>
  );
};

export default Hero;
